import { useState } from "react";
import { toast } from 'react-toastify';
import useFetch from '../services/fetch';
export const usePaymentSlip = () => {
	const { fetchApi } = useFetch();
	const [ bankInfo, setBankInfo ] = useState({
		utr: '',
		amount: '',
		file: ''
	});

	const [ bankInfoValid, setBankInfoValid ] = useState({
		utr: false,
		amount: false,
		file: false
	});

	const [touched, setTouched] = useState({
  		utr: false,
  		amount: false,
  		file: false
  	});

  	const convertFileToBase64 = (file) => {
	    return new Promise((resolve, reject) => {
	        const reader = new FileReader();
	        reader.onloadend = () => {
	            resolve(reader.result.split(',')[1]); // Get base64 string without metadata
	        };
	        reader.onerror = reject;
	        reader.readAsDataURL(file);
	    });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(!bankInfoValid.utr || !bankInfoValid.amount){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });
		} else {
			const siteValues = JSON.parse(localStorage.getItem('siteValues'));
			console.log('amount:', bankInfo.amount);
			console.log('type of amount:', typeof(String(bankInfo.amount)));
			console.log('site:', siteValues.site );
			console.log('utr:', bankInfo.utr );
			console.log('file:', bankInfo.file );
			const file = bankInfo.file;
			const base64Data = await convertFileToBase64(file);
			const proof = {
	            data: base64Data,
	            originalname: file.name,
	            mimetype: file.type
	        };
			const body = {
				amount: String(bankInfo.amount),
				site: siteValues.site,
				utrNumber: bankInfo.utr,
				subClientUsername: siteValues.username,
				proof: proof
			};
			const loginData = localStorage.getItem('loginData');
			const token = (JSON.parse(loginData)).token;
			const customHeaders = {
			  'Authorization': `Bearer ${token}`,
			};
			await fetchApi('https://api.jsrplay247.com/sub-client/deposit', 'POST', body, customHeaders);
			resetValidation();
		};
	};

	const resetValidation = () => {
		setBankInfo({ ...bankInfo, utr: '', amount: '', file: ''});
		setBankInfoValid({ ...bankInfoValid, utr: false, amount: false, file: false });
		setTouched({ ...touched, utr: false, amount: false, file: false });
		// Clear all input file elements
	    const inputFileElements = document.getElementsByClassName('inputFile');
	    for (let i = 0; i < inputFileElements.length; i++) {
	        inputFileElements[i].value = ''; // Clear each input file element
	    };

	    // Remove 'was-validated' class from all depositForm elements
	    const depositFormElements = document.getElementsByClassName('depositForm');
	    for (let i = 0; i < depositFormElements.length; i++) {
	        depositFormElements[i].classList.remove('was-validated'); // Remove class from each element
	    };
	};

	const onHandleUtr = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, utr: value });
		setBankInfoValid({ ...bankInfoValid, utr: value.length < 6 || value.length > 12 ? false : true });
		setTouched((prev) => ({ ...prev, utr: true }));
	};

	const onHandleAmount = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, amount: value });
		const isValid = value.length >= 3  && value.length <= 7;
		setBankInfoValid({ ...bankInfoValid, amount: isValid });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	const onHandleFile = (e) => {
		const file = e.target.files[0];
		setBankInfo({ ...bankInfo, file: file });
		const isValid = file ? true : false ;
		setBankInfoValid({ ...bankInfoValid, file: isValid });
		setTouched((prev) => ({ ...prev, file: true }));
	};

	const calculateAmount = (amount) => {
		setBankInfo((prevBankInfo) => ({
			...prevBankInfo,
			amount: Number(prevBankInfo.amount || 0) + Number(amount) 
		}));
		setBankInfoValid({...bankInfoValid, amount: true });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	return{
		bankInfo,
		setBankInfo,
		onHandleAmount,
		onHandleUtr,
		onHandleFile,
		bankInfoValid,
		touched,
		handleSubmit,
		calculateAmount
	};
};
