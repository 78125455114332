import { SHOW, HIDE } from './action';

const initialState = {
    hideButton: false
};

const showHideReducer = (state = initialState, action) => {
    switch(action.type) {
        case SHOW:
            return {
                ...state,
                hideButton: true
            };
        case HIDE:
            return {
                ...state,
                hideButton: false
            };
        default:
            return state;
    }
};

export default showHideReducer;
