import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch} from 'react-redux';
import useFetch from '../services/fetch';
import { refresh } from '../redux/refresh/action';
import { useWithdrawalSlip } from './withdrawalslipHook'
export const useAddAccount = () => {
	const { fetchApi } = useFetch();
	const { getAccounts } = useWithdrawalSlip();
	const dispatch = useDispatch();
	const [ accountInfo, setAccountInfo ] = useState({
		accounType: '',
		holderName: '',
		bankName: '',
		branch: '',
		accountNumber: '',
		ifscCode: ''
	});

	const [ accountInfoValid, setAccountInfoValid ] = useState({
		accounType: false,
		holderName: false,
		bankName: false,
		branch: false,
		accountNumber: false,
		ifscCode: false
	});

	const [touchedAccount, setTouchedAccount] = useState({
		accounType: false,
		holderName: false,
		bankName: false,
		branch: false,
		accountNumber: false,
		ifscCode: false
	});

	const handleSubmit = async(event) => {
		event.preventDefault();
		if( !accountInfoValid.accounType || !accountInfoValid.holderName || !accountInfoValid.bankName || !accountInfoValid.branch || !accountInfoValid.accountNumber || !accountInfoValid.ifscCode){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });
		    return;
		} else {
			const loginData = localStorage.getItem('loginData');
	    	const token = (JSON.parse(loginData)).token;
	    	const customHeaders = {
			  'Authorization': `Bearer ${token}`,
			};

			const body = {
				accountType: accountInfo.accounType,
				holderName: accountInfo.holderName,
				accountNumber: accountInfo.accountNumber,
				ifscCode: accountInfo.ifscCode,
				bankName: accountInfo.bankName,
				branch: accountInfo.branch
			};

			const result = await fetchApi('https://api.jsrplay247.com/client/bank-account/add', "POST", body, customHeaders )
			if (result.statusCode === 201) {
				toast.success(result.message, {
					theme: 'colored'
				});
				dispatch(refresh());
				resetValidation();
				getAccounts();
				document.getElementById('pills-existing-tab').click();
			};;	
		};
	};

	const resetValidation = () => {
		setAccountInfo({ 
			accounType: '', 
			holderName: '', 
			bankName: '', 
			branch: '', 
			accountNumber: '', 
			ifscCode: '' 
		});

		setAccountInfoValid({ 
			accounType: false, 
			holderName: false, 
			bankName: false, 
			branch: false, 
			accountNumber: false, 
			ifscCode: false 
		});

		setTouchedAccount({ 
			accounType: false, 
			holderName: false, 
			bankName: false, 
			branch: false, 
			accountNumber: false, 
			ifscCode: false
		});
		document.getElementById('addAccountForm').classList.remove('was-validated');
	};

	const onHandleAccountType = (e) => {
		const value = e.target.value;
		const isValid = value !== '' ? true : false ;
		setAccountInfo({ ...accountInfo, accounType: value });
		setAccountInfoValid({ ...accountInfoValid, accounType: isValid });
		setTouchedAccount((prev) => ({ ...prev, accounType: true }));
	};

	const onHandleHolderName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value) && value.length >= 2 && value.length <= 20;
		setAccountInfo({ ...accountInfo, holderName: value });
		setAccountInfoValid({ ...accountInfoValid, holderName: isValid });
		setTouchedAccount((prev) => ({ ...prev, holderName: true }));
	};

	const onHandleBankName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value) && value.length >= 3 && value.length <= 20;
		setAccountInfo({ ...accountInfo, bankName: value });
		setAccountInfoValid({ ...accountInfoValid, bankName: isValid });
		setTouchedAccount((prev) => ({ ...prev, bankName: true }));
	};

	const onHandleBranchName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value) && value.length >= 5 && value.length <= 20;
		setAccountInfo({ ...accountInfo, branch: value });
		setAccountInfoValid({ ...accountInfoValid, branch: isValid });
		setTouchedAccount((prev) => ({ ...prev, branch: true }));
	};

	const onHandleAccountNmbr = (e) => {
		const value = e.target.value;
		setAccountInfo({ ...accountInfo, accountNumber: value });
		setAccountInfoValid({ ...accountInfoValid, accountNumber: value.length < 12 || value.length > 14 ? false : true });
		setTouchedAccount((prev) => ({ ...prev, accountNumber: true }));
	};

	const onHandleIfscCode = (e) => {
		const ifscCodeRegex = /^[A-Z]{4}0\d{6}$/
		const value = e.target.value.toUpperCase();
		const isValid = ifscCodeRegex.test(value);
		setAccountInfo({ ...accountInfo, ifscCode: value });
		setAccountInfoValid({ ...accountInfoValid, ifscCode: isValid });
		setTouchedAccount((prev) => ({ ...prev, ifscCode: true }));
	};
	return {
		accountInfo,
		accountInfoValid,
		touchedAccount,
		onHandleAccountType,
		onHandleHolderName,
		onHandleBankName,
		onHandleBranchName,
		onHandleAccountNmbr,
		onHandleIfscCode,
		handleSubmit
	};
};