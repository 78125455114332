import React from 'react';
import { Oval } from 'react-loader-spinner'

const Loader = () => {
	return(
		<div className="loader-overlay">
			<Oval
			  visible={true}
			  height="80"
			  width="80"
			  color="#4051b6"
			  secondaryColor="#4051b6"
			  ariaLabel="oval-loading"
			  wrapperStyle={{}}
			  wrapperClass=""
			 />
		</div>
	);
}

export default Loader;