import { useEffect } from 'react';

export function useSidebarToggle() {
  useEffect(() => {
    const body = document.body;
    const sidebar = document.querySelector('.sidebar');
    const sidebarToggles = document.querySelectorAll('#sidebarToggle, #sidebarToggleTop');

    
    // Toggle sidebar on button click
    const handleSidebarToggle = () => {
      body.classList.toggle('sidebar-toggled');
      sidebar.classList.toggle('toggled');
      if (sidebar.classList.contains('toggled')) {
        const collapses = sidebar.querySelectorAll('.collapse');
        collapses.forEach(collapse => {
          if (collapse.classList.contains('show')) {
            collapse.classList.remove('show');
          }
        });
      }
    };

    body.classList.add('sidebar-toggled');
    sidebar.classList.add('toggled');
    sidebarToggles.forEach(toggle => toggle.classList.add('toggled'));

    // Add event listeners for toggling sidebar
    sidebarToggles.forEach(toggle => {
      toggle.addEventListener('click', handleSidebarToggle);
    });

    // Handle window resizing
    const handleResize = () => {
      if (window.innerWidth < 768) {
        const collapses = sidebar.querySelectorAll('.collapse');
        collapses.forEach(collapse => collapse.classList.remove('show'));
      }

      if (window.innerWidth < 480 && !sidebar.classList.contains('toggled')) {
        body.classList.add('sidebar-toggled');
        sidebar.classList.add('toggled');
        const collapses = sidebar.querySelectorAll('.collapse');
        collapses.forEach(collapse => collapse.classList.remove('show'));
      }
    };

    // Scroll event for scroll to top button
    const handleScroll = () => {
      const scrollTopBtn = document.querySelector('.scroll-to-top');
      if (window.scrollY > 100) {
        scrollTopBtn?.classList.add('show');
      } else {
        scrollTopBtn?.classList.remove('show');
      }
    };

    // Smooth scrolling for the scroll to top button
    const handleScrollToTop = (event) => {
      event.preventDefault();
      const target = document.querySelector(event.target.getAttribute('href'));
      target?.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollTopLinks = document.querySelectorAll('a.scroll-to-top');
    scrollTopLinks.forEach(link => link.addEventListener('click', handleScrollToTop));

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up listeners on component unmount
      sidebarToggles.forEach(toggle => toggle.removeEventListener('click', handleSidebarToggle));
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      scrollTopLinks.forEach(link => link.removeEventListener('click', handleScrollToTop));
    };
  }, []);
}
