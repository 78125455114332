import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { show, hide } from '../redux/showhide/action';
import { login, logout } from '../redux/login/action';
import { refresh } from '../redux/refresh/action';
import useFetch from '../services/fetch';
export const useSiteHook = () => {
	const { fetchApi } = useFetch();
	const dispatch = useDispatch();
	const [ sites, setSites ] = useState('');
	const [ fields, setFields ] = useState({
		username: '',
	});
	const [touched, setTouched] = useState({
  		username: false,
  		password: false
  	});
  	const [isUsernameValid, setIsUsernameValid] = useState(false);
	const login = useSelector((state) => state.login.login);
	const prevLogin = useRef(login);

	const getId = async () => {
		const loginData = localStorage.getItem('loginData');
		if(loginData) {
			const parsedLoginData = JSON.parse(loginData);
			const token = loginData ? parsedLoginData.token : null;
			const customHeaders = {
			  'Authorization': `Bearer ${token}`,
			};
			const result = await fetchApi('https://api.jsrplay247.com/sub-client/list', 'GET', null, customHeaders);
			if(result.statusCode === 200){
				setSites(result.data);
			}else if(result.error === 'Session Expired!') {
				localStorage.clear();
				dispatch(hide());
		    	dispatch(logout());
			}else if(result.error === 'No third parties found!'){
				console.log(result.error);
			};
		};
	};

	useEffect(() => {
        if (login && prevLogin.current === false) {
            console.log('logging in...');
        } else if (!login && prevLogin.current === true) {
            setSites([]); 
        }
        prevLogin.current = login;
    }, [login]);

	const handleInputChange = (event) => {
    	const { name, value } = event.target;
	    setFields((prevProps) => ({
	      ...prevProps,
	      [name]: value
	    }));
	    if (name === "username") {
	    	value.length < 5 ? setIsUsernameValid(false) : setIsUsernameValid(true);
    		setTouched((prev) => ({ ...prev, username: true }));
	    };
	};

	const handleCreateId = async (event) => {
		event.preventDefault();
		if(fields.username !== ''){
			console.log('siteName', localStorage.getItem('siteName'))
			const loginData = localStorage.getItem('loginData');
			if(loginData) {
				const parsedLoginData = JSON.parse(loginData);
				const token = loginData ? parsedLoginData.token : null;
				var siteName = localStorage.getItem('siteName');
				const body = {
					username: fields.username,
					site: siteName
				}
				const customHeaders = {
				  'Authorization': `Bearer ${token}`,
				};
				const result = await fetchApi('https://api.jsrplay247.com/sub-client/create', 'POST', body, customHeaders);
				console.log('result create id', result);
				if(result.statusCode === 201){
					resetCredentials();
					// window.location.reload();
					dispatch(refresh());
					document.getElementById('closeCreateIdModalButton').click();
				};
			};
		};
	};

	const handleSiteName = (site) => {
		localStorage.setItem('siteName', site);
	};

	const resetCredentials = () => {
		setFields({
			username: ''
		});
	};

	return {
		getId,
		sites,
		handleInputChange,
		fields,
		touched,
		handleSiteName,
		isUsernameValid,
		handleCreateId,
	};
};