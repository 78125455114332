import { useState } from 'react';
import { toast } from 'react-toastify';
import useFetch from '../services/fetch';
import { DisableAccountModal } from '../subcomponents/modal';
export const useWithdrawalSlip = () => {
	const { fetchApi } = useFetch();
	const [ accountId, setAccountId] = useState('');
	const [ bankInfo, setBankInfo ] = useState({
		accountNumber: '',
		amount: '',
		accountName: ''
	});

	const [ bankInfoValid, setBankInfoValid ] = useState({
		accountNumber: false,
		amount: false,
		accountName: false
	});

	const [ accounts, setAccounts ] = useState();

	const [touched, setTouched] = useState({
  		accountNumber: false,
  		amount: false,
  		accountName: false
  	});

	const handleWithdrawalSubmit = async (event) => {
		event.preventDefault();
		if(!bankInfoValid.accountNumber || !bankInfoValid.amount || !bankInfoValid.accountName){
			toast.error('Please Check Error Fields.', {
		      theme: "colored",
		    });

		    return;
		} else {
			const siteValues = JSON.parse(localStorage.getItem('siteValues'));
			const body = {
				accountId: accountId,
				amount: String(bankInfo.amount),
				site: siteValues.site,
				subClientUsername: siteValues.username
			};
			const loginData = localStorage.getItem('loginData');
			const token = (JSON.parse(loginData)).token;
			const customHeaders = {
			  'Authorization': `Bearer ${token}`,
			};
			await fetchApi('https://api.jsrplay247.com/sub-client/withdrawal', 'POST', body, customHeaders);
			resetValidation();
		}
	};

	const resetValidation = () => {
		setBankInfo({ 
			accountNumber: '', 
			amount: '', 
			accountName: '' 
		});

		setBankInfoValid({ 
			accountNumber: false, 
			amount: false, 
			accountName: false 
		});

		setTouched({
			accountNumber: false,
			amount: false,
			accountName: false
		});

		document.getElementById('withdrawaAccountForm').classList.remove('was-validated');
	};

	const getAccounts = async () => {
		const loginData = localStorage.getItem('loginData');
		const token = (JSON.parse(loginData)).token;
		const customHeaders = {
		  'Authorization': `Bearer ${token}`,
		};
		const result = await fetchApi('https://api.jsrplay247.com/client/bank-account/list', 'GET', null, customHeaders);
		if (result.statusCode === 200) {
			setAccounts(result.data);
		};;	
	}

	const onHandleAccountNumber = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, accountNumber: value });
		setBankInfoValid({ ...bankInfoValid, accountNumber: value.length < 12 || value.length > 14 ? false : true });
		setTouched((prev) => ({ ...prev, accountNumber: true }));
	};

	const onHandleAccountName = (e) => {
		const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
		const value = e.target.value;
		const isValid = fullNameRegex.test(value) && value.length >= 5 && value.length <=20;
		setBankInfo({ ...bankInfo, accountName: value });
		setBankInfoValid({ ...bankInfoValid, accountName: isValid });
		setTouched((prev) => ({ ...prev, accountName: true }));
	};

	const onHandleAmount = (e) => {
		const value = e.target.value;
		setBankInfo({ ...bankInfo, amount: value });
		const isValid = value.length >= 3  && value.length <= 7;
		setBankInfoValid({ ...bankInfoValid, amount: isValid });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	const handleAccountNumberName = (accountNumber, accountName, id) => {
		setBankInfo({
			accountNumber: accountNumber,
			accountName: accountName
		});

		setBankInfoValid({
			accountNumber: true,
			accountName: true
		});

		setAccountId(id);
	};

	const calculateAmount = (amount) => {
		setBankInfo((prevBankInfo) => ({
			...prevBankInfo,
			amount: Number(prevBankInfo.amount || 0) + Number(amount) 
		}));
		setBankInfoValid({...bankInfoValid, amount: true });
		setTouched((prev) => ({ ...prev, amount: true }));
	};

	const showDisableAccountModal = ( holderName, accountNumber, accountType ) => {
		<DisableAccountModal id="Modal5" />
	}
	return{
		bankInfo,
		bankInfoValid,
		touched,
		onHandleAccountNumber,
		onHandleAccountName,
		onHandleAmount,
		calculateAmount,
		handleWithdrawalSubmit,
		getAccounts,
		handleAccountNumberName,
		showDisableAccountModal,
		accounts
	};
};