import PaymentSlip from '../paymentslip';
import AccountInfo from '../accountinfo';
const IMPS = () => {
	return(
		<div className="text-center">
			<div className="get-payment-details">
				<AccountInfo/>
				<PaymentSlip/>
			</div>
		</div>
	);
};

export default IMPS;

