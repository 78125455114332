import { useEffect } from 'react';
export const useValidationHook = () => {
  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
      const handleSubmit = (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      };

      form.addEventListener('submit', handleSubmit, false);

      // Cleanup the event listener when the component unmounts
      return () => {
        form.removeEventListener('submit', handleSubmit, false);
      };
    });
  }, []);
};