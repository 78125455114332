import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useSiteHook } from '../../hooks/siteHook';
import { CreateIdModal } from '../../subcomponents/modal'

const MultiId = () => {
    // State to trigger a re-render
    const { handleSiteName, sites, getId } = useSiteHook();
    const [token, setToken] = useState();
    const [renderCount, setRenderCount] = useState(0);
    const hideButton = useSelector((state) => state.showHide.hideButton);
    const refreshCount = useSelector((state) => state.refresh.refreshCount);
    useEffect(() => {
        const loginData = localStorage.getItem('loginData');
        if (loginData) {
            const { token } = JSON.parse(loginData);
            setToken(token || null);
        }
    }, []);

    useEffect(() => {
        setRenderCount((prev) => prev + 1);
        getId();
    }, [refreshCount]);
    return (
        <>
            {/*<p className="text-white">{refreshCount}</p>*/}
            <div className="text-center col-xl-12 mb-4">
                <div className="">
                    <ul className="nav nav-pills d-inline-flex justify-content-between align-items-center tab-wrapper-create shadow border border-white" id="pills-tab" role="tablist" style={{padding: '10px 0px'}}>
                      <li className="nav-item" role="presentation">
                        <a href="/" className="tab px-sm-5 px-3 description-text" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">My ID's</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a href="/" className="tab px-sm-5 px-3 description-text active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Create ID</a>
                      </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">  
                    <div className="row d-flex justify-content-center">               
                        { hideButton && Array.isArray(sites) && sites.length ? (
                            sites.map((site, index ) => (
                            <div key={index} className="col-xl-4 col-md-6 mb-4">
                                <div className="card border-bottom-primary shadow h-auto p-2">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={ site.site === 'diamond1' ? 'img/cricguru_logo.png' : site.site === 'lotus1' ? 'img/lotus_logo.png' : site.site === 'sky1' ? 'img/sky_exch_logo.png' : 'No Image Found' } alt="..." />
                                            <div className="col">
                                                <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                    <Link to="/" className="text-white text-decoration-none"> {site.site === 'diamond1' ? 'Diamond' : site.site === 'lotus1' ? 'Lotus' : 'Skyexch'} </Link>
                                                </div>
                                                <p className="m-0 text-center text-white">Username: <span>{site.username}</span></p>
                                                <p className="text-center text-white">Password: <span>{site.password}</span></p>
                
                                                <p className="m-0 d-block text-center">
                                                    <Link to="/withdrawal" className="btn btn-primary btn-circle withdraw" aria-label="Withdraw" onClick={() => {localStorage.setItem('siteValues', JSON.stringify({site: site.site, username: site.username}))}}></Link>
                                                    <Link to="/" className="btn btn-success btn-circle checkbalance mx-1" aria-label="CheckBalance"></Link>
                                                    <Link to="/deposit" className="btn btn-warning btn-circle deposit" aria-label="Deposit" onClick={() => {localStorage.setItem('siteValues', JSON.stringify({site: site.site, username: site.username}))}}></Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))
                            ) : (
                                <>
                                </>
                            )
                        }
                    </div>
                  </div>
                  <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                        {((!Array.isArray(sites) || sites.length === 0) && !token) && (
                            ['diamond1', 'lotus1', 'sky1'].map((siteName) => {
                                const logoSrc = {
                                    diamond1: "img/cricguru_logo.png",
                                    lotus1: "img/lotus_logo.png",
                                    sky1: "img/sky_exch_logo.png",
                                };

                                const siteLink = {
                                    diamond1: "https://cricguru9.com/",
                                    lotus1: "https://www.lotusbook247.com/",
                                    sky1: "https://www.skyexch.vip/",
                                };

                                return (
                                    <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                        <div className="card border-bottom-primary shadow h-auto p-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                    <div className="col">
                                                        <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                            <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                {siteName === 'diamond1' ? 'Diamond' : siteName === 'lotus1' ? 'Lotus' : 'Skyexch'}
                                                            </a>
                                                        </div>
                                                        <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} disabled>
                                                            Create ID
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}

                        {((!Array.isArray(sites) || sites.length === 0) && token) && (
                            ['diamond1', 'lotus1', 'sky1'].map((siteName) => {
                                const logoSrc = {
                                    diamond1: "img/cricguru_logo.png",
                                    lotus1: "img/lotus_logo.png",
                                    sky1: "img/sky_exch_logo.png",
                                };

                                const siteLink = {
                                    diamond1: "https://cricguru9.com/",
                                    lotus1: "https://www.lotusbook247.com/",
                                    sky1: "https://www.skyexch.vip/",
                                };

                                return (
                                    <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                        <div className="card border-bottom-primary shadow h-auto p-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                    <div className="col">
                                                        <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                            <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                {siteName === 'diamond1' ? 'Diamond' : siteName === 'lotus1' ? 'Lotus' : 'Skyexch'}
                                                            </a>
                                                        </div>
                                                        <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} data-bs-toggle="modal" data-bs-target="#Modal3" onClick={() => handleSiteName(siteName)}>
                                                            Create ID
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}

                        {Array.isArray(sites) && sites.length > 0 && (
                            ['diamond1', 'lotus1', 'sky1'].map((siteName) => {
                                const siteExists = sites.some((site) => site.site === siteName);

                                if (!siteExists) {
                                    const logoSrc = {
                                        diamond1: "img/cricguru_logo.png",
                                        lotus1: "img/lotus_logo.png",
                                        sky1: "img/sky_exch_logo.png",
                                    };

                                    const siteLink = {
                                        diamond1: "https://cricguru9.com/",
                                        lotus1: "https://www.lotusbook247.com/",
                                        sky1: "https://www.skyexch.vip/",
                                    };

                                    return (
                                        <div className="col-xl-4 col-md-6 mb-4" key={siteName}>
                                            <div className="card border-bottom-primary shadow h-auto p-2">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}} src={logoSrc[siteName]} alt={`${siteName} logo`} />
                                                        <div className="col">
                                                            <div className="text-sm font-weight-bold text-primary text-uppercase mb-2 text-center">
                                                                <a href={siteLink[siteName]} className="text-white text-decoration-none" target="_blank" rel="noreferrer">
                                                                    {siteName === 'diamond1' ? 'Diamond' : siteName === 'lotus1' ? 'Lotus' : 'Skyexch'}
                                                                </a>
                                                            </div>
                                                            <button className="btn btn-block btn-light fw-semibold" style={{color:'#26283c'}} data-bs-toggle="modal" data-bs-target="#Modal3" onClick={() => handleSiteName(siteName)}>
                                                                Create ID
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }

                                return null;
                            })
                        )}
                    </div>
                  </div>
            </div>
            <CreateIdModal id="Modal3"/>
        </>
    );
}

export default MultiId;
