export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const login = () => ({
    type: LOGIN
});

export const logout = () => ({
    type: LOGOUT
});
