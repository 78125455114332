const Footer = () => {
	return (
		<footer className="sticky-footer text-white shadow" style={{backgroundColor: '#2e2b71'}}>
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; jsrplay247.com</span>
                </div>
            </div>
        </footer>
	)
}

export default Footer;