import { useState } from 'react';
import { useDispatch } from "react-redux";
import { loadingStart, loadingStop } from "../redux/loader/action";
import { handleResponseStatus } from "../middleware/statusMiddleware";
import { useNavigate } from 'react-router-dom';

const useFetch = () => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // This function accepts 'method' (e.g., GET, POST) and 'data' (body of the request)
  const fetchApi = async (url, method, data, customHeaders = {}) => {
    dispatch(loadingStart());
    setError(null);

    const defaultHeaders = {
      'Content-Type': 'application/json',
    };

    const options = {
      method: method,
      headers: {
       ...defaultHeaders,
       ...customHeaders
      },
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('response', response);
      console.log(result);
      handleResponseStatus(response, result, dispatch, navigate);

      if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
      }

      dispatch(loadingStop());
      return result; 
    } catch (error) {
      dispatch(loadingStop());
      setError(error.message);
      return { error: error.message }; 
    }
  };

  return { fetchApi, error };
};

export default useFetch;
