import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useValidationHook } from '../../hooks/validationHook';
import { useWithdrawalSlip } from '../../hooks/withdrawalslipHook';
import { useAddAccount } from '../../hooks/addaccountHook';
import { DisableAccountModal } from '../../subcomponents/modal';
const Withdrawal = () => {
	const [renderCount, setRenderCount] = useState(0);
	const hideButton = useSelector((state) => state.showHide.hideButton);
	const [selectedCardIndex, setSelectedCardIndex] = useState(null);
	useValidationHook();
	const {
		bankInfo,
		bankInfoValid,
		touched,
		onHandleAccountNumber,
		handleAccountNumberName,
		onHandleAccountName,
		onHandleAmount,
		calculateAmount,
		handleWithdrawalSubmit,
		showDisableAccountModal,
		getAccounts,
		accounts
	} = useWithdrawalSlip();

	const handleCardClick = (index, accountNumber, holderName, id) => {
		setSelectedCardIndex(index);
		handleAccountNumberName(accountNumber, holderName, id);
	};

	const {
		accountInfo,
		accountInfoValid,
		touchedAccount,
		onHandleAccountType,
		onHandleHolderName,
		onHandleBankName,
		onHandleBranchName,
		onHandleAccountNmbr,
		handleSubmit,
		onHandleIfscCode,
	} = useAddAccount();

	useEffect(() => {
		getAccounts();
	}, []);
	return (
		<div>
			<div className="withdrawal text-white">
				<ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
				  <li className="nav-item" role="presentation">
				    <button className="nav-link text-white" id="pills-add-tab" data-bs-toggle="pill" data-bs-target="#pills-add" type="button" role="tab" aria-controls="pills-add" aria-selected="true">Add Account</button>
				  </li>
				  <li className="nav-item" role="presentation">
				    <button className="nav-link active text-white" id="pills-existing-tab" data-bs-toggle="pill" data-bs-target="#pills-existing" type="button" role="tab" aria-controls="pills-existing" aria-selected="false">Existing Account</button>
				  </li>
				</ul>
				<div className="tab-content" id="pills-tabContent">
				  <div className="tab-pane fade" id="pills-add" role="tabpanel" aria-labelledby="pills-add-tab" tabIndex="0">
				  	<div className="d-flex justify-content-center">
						<div className="payment-slip">
							<div className="card text-white border border-light my-2">
							  <div className="card-body">
							    <h5 className="card-title text-uppercase fw-bold">Withdrawal Slip</h5>
							    <form id="addAccountForm" className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
								  	<div className="text-start">
									  <label htmlFor="inputPaymentOption" className="form-label">Account Type</label>
									  <select className={`form-select ${touchedAccount.accounType && !accountInfoValid.accounType ? 'is-invalid' : ''}`} id="inputPaymentOption" onInput={onHandleAccountType} value={accountInfo.accounType} required>
									  	<option value="">--Select--</option>
									  	<option value="current">Current</option>
									  	<option value="saving">Saving</option>
									  </select>
									  <div className="invalid-feedback">
								      	Please Select An Option.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputHolderName" className="form-label">Holder Name</label>
									  <input type="text" className={`form-control ${touchedAccount.holderName && !accountInfoValid.holderName ? 'is-invalid' : ''}`} id="inputHolderName" onInput={onHandleHolderName} value={accountInfo.holderName} placeholder="Holder Name" required/>
									  <div className="invalid-feedback">
									      Minimum 2 And Maximum 20 Characters Are Required.
									  </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputBankName" className="form-label">Bank Name</label>
									  <input type="text" className={`form-control ${touchedAccount.bankName && !accountInfoValid.bankName ? 'is-invalid' : ''}`} id="inputBankName" onInput={onHandleBankName} value={accountInfo.bankName} placeholder="Bank Name" required/>
									  <div className="invalid-feedback">
									      Minimum 3 And Maximum 20 Characters Are Required.
									  </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputBranch" className="form-label">Branch</label>
									  <input type="text" className={`form-control ${touchedAccount.branch && !accountInfoValid.branch ? 'is-invalid' : ''}`} id="inputBranch" onInput={onHandleBranchName} value={accountInfo.branch} placeholder="Branch" required/>
									  <div className="invalid-feedback">
									      Minimum 5 And Maximum 20 Characters Are Required.
									  </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAccountNumber" className="form-label">Account Number</label>
									  <input type="number" className={`form-control ${touchedAccount.accountNumber && !accountInfoValid.accountNumber ? 'is-invalid' : ''}`} id="inputAccountNumber" onInput={onHandleAccountNmbr} value={accountInfo.accountNumber} placeholder="Account Number" required/>
									  <div className="invalid-feedback">
									      Minimum 12 And Maximum 14 Characters Are Required.
									    </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputIfscNumber" className="form-label">IBAN/IFSC Number</label>
									  <input type="text" className={`form-control ${touchedAccount.ifscCode && !accountInfoValid.ifscCode ? 'is-invalid' : ''}`} id="inputIfscNumber" onInput={onHandleIfscCode} value={accountInfo.ifscCode} placeholder="IBAN/IFSC Number" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid IFSC Number For Example : "ABCD0789876".
								      </div>
									</div>
								  	<div className="col-12">
								      <button className="btn btn-warning fw-bold text-uppercase w-100" type="submit" disabled={!accountInfoValid.holderName || !accountInfoValid.bankName || !accountInfoValid.branch || !accountInfoValid.accountNumber || !accountInfoValid.ifscCode}>Add Account</button>
								 	</div>
								</form>
							  </div>
							</div>
						</div>
					</div>
				  </div>
				  <div className="tab-pane fade show active" id="pills-existing" role="tabpanel" aria-labelledby="pills-existing-tab" tabIndex="0">
				  	<ul className="d-flex flex-nowrap overflow-auto text-decoration-none">
			  			{ hideButton && Array.isArray(accounts) && accounts.length ? (
			  				accounts.map((account, index ) => (
				  			<li key={index} className="mx-1 list-unstyled" >
						  		<div className={`card text-white ${selectedCardIndex === index ? 'border border-light' : ''}`} onClick={() => handleCardClick(index, account.accountNumber, account.holderName, account.id)}>
						  			<div className="card-header border-0 p-2">
						  				<div className="d-flex justify-content-between">
											<p className="card-text fw-bold mb-0 text-danger" data-bs-toggle="modal" data-bs-target={`#disable_account_modal_${index}`}><i className="fas fa-trash"></i></p>
											<p className={`card-text fw-bold mb-0 ${selectedCardIndex === index ? 'text-success' : 'text-secondary'}`}><i className="fas fa-check-circle"></i></p>
										</div>
									</div>
								  	<div className="card-body p-2">
								    	<p className="card-title fw-semibold mb-1">{account.holderName}</p>
								    	<p className="card-text fw-semibold mb-1">{account.accountNumber}</p>
								    	<p className="card-text fw-semibold mb-1">{account.accountType}</p>
								  	</div>
								</div>
								<DisableAccountModal id={`disable_account_modal_${index}`} holderName={account.holderName} accountNumber={account.accountNumber} accountType={account.accountType} />
							</li>
							))
							) : (
								<></>
							)
						}
				  	</ul>
				  	<div className="d-flex justify-content-center">
						<div className="payment-slip">
							<div className="card text-white border border-light my-2">
							  <div className="card-body">
							    <h5 className="card-title text-uppercase fw-bold">Withdrawal Slip</h5>
							    <form id="withdrawaAccountForm" className="row g-3 needs-validation" onSubmit={handleWithdrawalSubmit} noValidate>
								  	<div className="text-start">
									  <label htmlFor="inputAccountNumber" className="form-label">Account Number</label>
									  <input type="number" className={`form-control ${touched.accountNumber && !bankInfoValid.accountNumber ? 'is-invalid' : ''}`} id="inputAccountNumber" onInput={onHandleAccountNumber} value={bankInfo.accountNumber} placeholder="Account Number" readOnly required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Number Between 11 To 16 Digits.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAccountName" className="form-label">Account Name</label>
									  <input type="text" className={`form-control ${touched.accountName && !bankInfoValid.accountName ? 'is-invalid' : ''}`} id="inputAccountName" onInput={onHandleAccountName} value={bankInfo.accountName} placeholder="Account Name" readOnly required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Account Name.
								      </div>
									</div>
									<div className="text-start">
									  <label htmlFor="inputAmount" className="form-label">Amount</label>
									  <input type="number" className={`form-control ${touched.amount && !bankInfoValid.amount ? 'is-invalid' : ''}`} id="inputAmount" onInput={onHandleAmount}  value={bankInfo.amount} placeholder="Amount" required/>
									  <div className="invalid-feedback">
								      	Please Enter Valid Amount.
								      </div>
									</div>
									<div className="text-center">
										<div className="d-flex justify-content-center my-2">
											<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(100);
											}}>
											<p>100</p>
											</button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(200);
											}}><p>200</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(500);
											}}><p>500</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(1000);
											}}><p>1K</p></button>
										</div>
										<div className="d-flex justify-content-center my-2">
											<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(2000);
											}}><p>2K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(3000);
											}}><p>3K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(5000);
											}}><p>5K</p></button>
										  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
												e.preventDefault();
												calculateAmount(10000);
											}}><p>10K</p></button>
										</div>
									</div>
								  	<div className="col-12">
								      <button className="btn btn-warning fw-bold text-uppercase w-100" type="submit" disabled={!bankInfoValid.accountNumber || !bankInfoValid.amount || !bankInfoValid.accountName}>Withdraw</button>
								 	</div>
								</form>
							  </div>
							</div>
						</div>
					</div>
				  </div>
				</div>
			</div>
		</div>
	);
};

export default Withdrawal;