export const REFRESH = 'REFRESH';
export const INITIALSTATE = 'INITIALSTATE';
export const refresh = () => ({
    type: REFRESH
});

export const intialset = () => ({
    type: INITIALSTATE
});

