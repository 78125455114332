import { combineReducers } from 'redux';
import showHideReducer from './showhide/reducer';
import loginReducer from './login/reducer';
import loaderReducer from './loader/reducer';
import refreshReducer from './refresh/reducer';

export default combineReducers({
    showHide: showHideReducer,
    login: loginReducer,
    loader: loaderReducer,
    refresh: refreshReducer
});