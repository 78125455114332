import { Modal } from 'bootstrap';
const DisableAccountModal = (props) => {
    
    return(
		<>
			<div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="signUpModalLabel" aria-hidden="true">
		        <div className="modal-dialog">
		            <div className="modal-content bg-gradient-primary">
		                <div className="modal-header">
			                <p className="mb-0 text-secondary fw-semibold"> Are you sure you want to remove this account? </p>
			                <button id="closeDisableAccountModal" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
			                    <span aria-hidden="true" className="text-white">&times;</span>
			                </button>
		                </div>
		                <div className="modal-body">
			                <span className="d-flex justify-content-between"> <p className="mb-0 text-warning">Holder Name: </p><label>{props.holderName}</label></span>
			                <span className="d-flex justify-content-between"> <p className="mb-0 text-warning">Account Number: </p><label>{props.accountNumber}</label></span>
			                <span className="d-flex justify-content-between"> <p className="mb-0 text-warning">Account Type: </p><label>{props.accountType}</label></span>
		                </div>
		                <div className="modal-footer d-flex justify-content-between">
					        <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
					        <button type="button" className="btn btn-danger">Yes <i className="fas fa-trash"></i></button>
					    </div>
		            </div>
		        </div>
		    </div>
		</>
	)
}

export default DisableAccountModal;





