import { useLoginHook } from '../../hooks/loginHook';
import { useValidationHook } from '../../hooks/validationHook';
import { Modal } from 'bootstrap';
const SignUpModal = (props) => {
	useValidationHook();
	const closeSignUpModal = () => {
        const modalElement = document.getElementById(props.id);
        const modal = Modal.getInstance(modalElement);
        if (modal) {
            modal.hide();
        }
        document.getElementById('closeSignUpModalButton').click();
    };
    const { onChangeUserNameRegister, onChangePasswordRegister, credentialsRegister, handleRegister, isPasswordRegisterValid, isUsernameRegisterValid, touched } = useLoginHook();
	return(
		<>
			<div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="signUpModalLabel" aria-hidden="true">
		        <div className="modal-dialog">
		            <div className="modal-content bg-gradient-primary">
		                <div className="modal-header">
			                <img className="loginModal-logo" src="img/jsr-logo.png" width="100" alt="JSRPLAY247" />
			                <button id="closeSignUpModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
			                    <span aria-hidden="true" className="text-white">&times;</span>
			                </button>
		                </div>
		                <div className="modal-body">
			                <form className="needs-validation" onSubmit={handleRegister} noValidate>
			                    <div className="form-group">
			                        <label htmlFor="email" className="col-form-label">Username / Mobile Number</label>
			                        <input type="text" className={`form-control ${ touched.username && !isUsernameRegisterValid ? 'is-invalid' : ''}`} id="signupemail" onInput={onChangeUserNameRegister} value={credentialsRegister.username}  required/>
			                    	<div className="invalid-feedback">
								      Minimum 5 And Maximum 20 Characters Are Required.
								    </div>
			                    </div>
			                    <div className="form-group">
			                        <label htmlFor="password" className="col-form-label">Password:</label>
			                        <input type="password" className={`form-control ${ touched.password && !isPasswordRegisterValid ? 'is-invalid' : ''}`} id="signuppassword" onInput={onChangePasswordRegister} value={credentialsRegister.password} required/>
			                    	<div className="invalid-feedback">
								      Minimum 8 And Maximum 20 Characters Are Required.
								    </div>
			                    </div>
			                    <button type="submit" className="btn border-white text-white btn-block" disabled={!isUsernameRegisterValid || !isPasswordRegisterValid}>Register</button>
			                </form>
		                </div>
		            </div>
		        </div>
		    </div>
		</>
	)
}

export default SignUpModal;





