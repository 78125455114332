import { Link } from 'react-router-dom';
const Sidebar = () => {
	return(
		<>
			<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion shadow" id="accordionSidebar">

	            {/*Sidebar - Brand*/}
	            <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
	                <div className="sidebar-img-div"><img src="/img/jsr-logo.png" className="img-fluid" alt="JSRPLAY247" /></div>
	            </Link>

	            {/*Divider*/}
	            <hr className="sidebar-divider my-0" />

	            {/*Nav Item - Dashboard*/}
	            <li className="nav-item active">
	                <Link to="/" className="nav-link">
	                    <i className="fas fa-fw fa-tachometer-alt"></i>
	                    <span>Multi ID's Wallet</span>
	                </Link>
	            </li>

	            {/*Divider*/}
	            <hr className="sidebar-divider my-0" />
	            
	            {/*Nav Item - Charts*/}
	            <li className="nav-item">
	                <Link to="/" className="nav-link">
	                    <i className="fas fa-fw fa-chart-area"></i>
	                    <span>In Play</span>
	                </Link>
	            </li>

	            {/*Divider*/}
	            <hr className="sidebar-divider my-0" />

	            {/*Nav Item - Tables*/}
	            <li className="nav-item">
	                <Link to="/" className="nav-link">
	                    <i className="fas fa-fw fa-book"></i>
	                    <span>Sports Book</span>
	                </Link>
	            </li>

	            {/*Divider*/}
	            <hr className="sidebar-divider my-0" />

	            {/*Nav Item - Tables*/}
	            <li className="nav-item">
	                <Link to="/" className="nav-link">
	                    <i className="fas fa-fw fa-table"></i>
	                    <span>Transactions</span>
	                </Link>
	            </li>           

	            {/*Divider*/}
	            <hr className="sidebar-divider d-none d-md-block" />

	            {/*Sidebar Toggler (Sidebar)*/}
	            <div className="text-center d-none d-md-inline m-4">
	                <button className="rounded-circle border-0 bg-light" id="sidebarToggle"></button>
	            </div>



	            {/*Sidebar Message*/}
	            <div className="sidebar-card d-none d-lg-flex" style={{backgroundColor: '#26283c', color: '#fff'}}>
	                <img className="sidebar-card-illustration mb-2" src="https://jsrplay24.web.app/assets/whatsapp.png" alt="..." />
	                <p className="text-center mb-2">We offer <strong>24/7 online support </strong> for inquiries, emergencies, feedback, and complaints. Reach out anytime.</p>
	                <a className="btn btn-sm text-white" href="https://startbootstrap.com/theme/sb-admin-pro" style={{backgroundColor: "#26283c"}}>+91 9822828282</a>
	            </div>

	        </ul>
	    </>
	)
}

export default Sidebar;






