import PaymentSlip from '../paymentslip';
const Whatsapp = () => {
	const handleWhatsapp = (link) => {
        window.location.href = link;
    }

	return(
		<div className="text-center">
			<div>
				<span>
					<i className="fab fa-whatsapp text-success mx-2"></i>
						Get Whatsapp Details 
					<i className="fab fa-whatsapp text-success mx-2"></i>
				</span>
			</div>
			<div className="get-payment-details">
				<div className="d-flex justify-content-center">
					<div className="card-container">
						<div className="card text-white border border-light">
						  <div className="card-body">
						    <p className="card-title">Bank / IMPS Transfer</p>
						    <button className="btn btn-success" onClick={() => handleWhatsapp('https://wa.me/919257575720')}> <span className='blinking-text'> Click Here </span> </button>
						    <p className="card-text mt-2"><i className="fas fa-university"></i></p>
						  </div>
						</div>
					</div>
					<div className="card-container">
						<div className="card text-white border border-light">
						  <div className="card-body">
						    <p className="card-title">UPI / Phone Pe / GPay Transfer</p>
						    <button className="btn btn-success" onClick={() => handleWhatsapp('https://wa.me/919257575720')}> <span className='blinking-text'> Click Here </span> </button>
						    <p className="card-text mt-2"><i className="fas fa-exchange-alt"></i></p>
						  </div>
						</div>
					</div>
				</div>
				<PaymentSlip/>
			</div>
		</div>
	);
};

export default Whatsapp;
