import { REFRESH, INITIALSTATE } from './action';

const initialState = {
	refreshCount: 0
}

const refreshReducer = (state = initialState, action) => {
    switch(action.type) {
        case REFRESH:
            return {
                ...state,
                refreshCount: state.refreshCount +1
            };
        case INITIALSTATE:
            return {
                ...state,
                refreshCount: state.refreshCount = 0
            };
        default:
            return state;
    }
};

export default refreshReducer;