import { useSiteHook } from '../../hooks/siteHook';
import { useValidationHook } from '../../hooks/validationHook';
import { Modal } from 'bootstrap';
const CreateIdModal = (props) => {
	useValidationHook();
	const closeSignUpModal = () => {
        const modalElement = document.getElementById(props.id);
        const modal = Modal.getInstance(modalElement);
        if (modal) {
            modal.hide();
        }
        document.getElementById('closeSignUpModalButton').click();
    };
    const { handleInputChange, fields, handleCreateId, touched, isUsernameValid } = useSiteHook();
	return(
		<>
			<div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="createIdModalLabel" aria-hidden="true">
		        <div className="modal-dialog">
		            <div className="modal-content bg-gradient-primary">
		                <div className="modal-header">
			                <img className="loginModal-logo" src="img/jsr-logo.png" width="100" alt="JSRPLAY247" />
			                <button id="closeCreateIdModalButton" type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
			                    <span aria-hidden="true" className="text-white">&times;</span>
			                </button>
		                </div>
		                <div className="modal-body">
			                <form className="needs-validation" onSubmit={handleCreateId} noValidate>
			                    <div className="form-group">
			                        <label htmlFor="email" className="col-form-label">Username / Mobile Number</label>
			                        <input type="text" className={`form-control ${ touched.username && !isUsernameValid ? 'is-invalid' : ''}`} id="signupemail" name="username" value={fields.username} onInput={handleInputChange} required/>
			                    	<div className="invalid-feedback">
								      Please Enter Atleast 5 Characters.
								    </div>
			                    </div>
			                    <button type="submit" className="btn border-white text-white btn-block" disabled={!isUsernameValid }>Create Id</button>
			                </form>
		                </div>
		            </div>
		        </div>
		    </div>
		</>
	)
}

export default CreateIdModal;





