import PaymentSlip from '../paymentslip';
import AccountInfo from '../accountinfo';
const Account = () => {
	return(
		<div className="text-center">
			<div className="get-payment-details">
				<AccountInfo/>
				<PaymentSlip/>
			</div>
		</div>
	);
};

export default Account;

