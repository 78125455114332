import PaymentSlip from '../paymentslip';
import AccountInfo from '../accountinfo';
import { ScannerModal } from '../../modal';
const USDT = () => {
	return(
		<div className="text-center">
			<div>
				<button className="btn btn-default" style={{ backgroundColor: '#2e2b71', border: '1px solid #fff', color: '#fff', }} data-bs-toggle="modal" data-bs-target="#Modal3">Scan QR</button>
			</div>
			<div className="get-payment-details">
				<AccountInfo/>
				<PaymentSlip/>
			</div>
		<ScannerModal id="Modal3"/>
		</div>
	);
};

export default USDT;

