import { Link } from 'react-router-dom';
const ErrorPage = () => {
	return(
		<div className="">
			<div className="text-center mb-4">
				<h1 className="text-light"> 404 Page Not Found </h1>
				<Link to="/" className="btn btn-default text-light border border-light" style={{backgroundColor: '#2e2b71'}}>Back To Home</Link>
			</div>
			<div className="text-center">
				<img src="/img/error_img.png" alt="Error Image" style={{ height: '420px', width: 'auto' }} />
			</div>
		</div>
	);
};

export default ErrorPage;