import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Loader from './subcomponents/loader';
import Layout from './Layout.js';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useSidebarToggle } from './hooks/globalHook.js';
import { BrowserRouter as Router } from 'react-router-dom';
function App() {
  const loading = useSelector((state) => state.loader.loading);
  useSidebarToggle();
  return (
    <div className="App">
      {loading && <Loader/>}
      <Router>
        <Layout />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
