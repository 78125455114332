const ScannerModal = (props) => {
	return(
		<>
			<div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="signUpModalLabel" aria-hidden="true">
		        <div className="modal-dialog">
		            <div className="modal-content bg-gradient-primary">
		                <div className="modal-body">
			                <img className="img-fluid" src="img/demo-scanner.jpeg" alt="Scanner"/>
		                </div>
		            </div>
		        </div>
		    </div>
		</>
	)
}

export default ScannerModal;





