import Whatsapp from '../../subcomponents/tabs/whatsapp'
import UPI from '../../subcomponents/tabs/upi'
import PhonePe from '../../subcomponents/tabs/phonepe'
import Account from '../../subcomponents/tabs/account'
import IMPS from '../../subcomponents/tabs/imps'
import USDT from '../../subcomponents/tabs/usdt'
const Deposit = () => {
	return(
		<div>
			<div className="marquee-container text-white">
				<div className="nav-container" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
	                <ul className="nav nav-pills mb-3 d-flex flex-nowrap" id="pills-tab" role="tablist">
					  <li className="nav-item" role="presentation">
					    <button className="nav-link active" id="pills-whatsapp-img-tab" data-bs-toggle="pill" data-bs-target="#pills-whatsapp-img" type="button" role="tab" aria-controls="pills-whatsapp-img" aria-selected="true">
					    	<img src="img/whatsapp_img_deposit.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					  <li className="nav-item" role="presentation">
					    <button className="nav-link" id="pills-upi-tab" data-bs-toggle="pill" data-bs-target="#pills-upi" type="button" role="tab" aria-controls="pills-upi" aria-selected="false">
					    	<img src="img/UPI.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					  <li className="nav-item" role="presentation">
					    <button className="nav-link" id="pills-phonepe-tab" data-bs-toggle="pill" data-bs-target="#pills-phonepe" type="button" role="tab" aria-controls="pills-phonepe" aria-selected="false">
					    	<img src="img/PhonePe.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					  <li className="nav-item" role="presentation">
					    <button className="nav-link" id="pills-bank-tab" data-bs-toggle="pill" data-bs-target="#pills-bank" type="button" role="tab" aria-controls="pills-bank" aria-selected="false">
					    	<img src="img/Bank.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					  <li className="nav-item" role="presentation">
					    <button className="nav-link" id="pills-imps-tab" data-bs-toggle="pill" data-bs-target="#pills-imps" type="button" role="tab" aria-controls="pills-imps" aria-selected="false">
					    	<img src="img/IMPS.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					  <li className="nav-item" role="presentation">
					    <button className="nav-link" id="pills-usdt-tab" data-bs-toggle="pill" data-bs-target="#pills-usdt" type="button" role="tab" aria-controls="pills-usdt" aria-selected="false">
					    	<img src="img/USDT.svg" height="70" width="105" alt="" />
					    </button>
					  </li>
					</ul>
				</div>
				<div className="tab-content" id="pills-tabContent">
				  <div className="tab-pane fade show active" id="pills-whatsapp-img" role="tabpanel" aria-labelledby="pills-whatsapp-img-tab" tabIndex="0">
				  	<Whatsapp/>
				  </div>
				  <div className="tab-pane fade" id="pills-upi" role="tabpanel" aria-labelledby="pills-upi-tab" tabIndex="0">
				  	<UPI/>
				  </div>
				  <div className="tab-pane fade" id="pills-phonepe" role="tabpanel" aria-labelledby="pills-phonepe-tab" tabIndex="0">
				  	<PhonePe/>
				  </div>
				  <div className="tab-pane fade" id="pills-bank" role="tabpanel" aria-labelledby="pills-bank-tab" tabIndex="0">
				  	<Account/>
				  </div>
				  <div className="tab-pane fade" id="pills-imps" role="tabpanel" aria-labelledby="pills-imps-tab" tabIndex="0">
				  	<IMPS/>
				  </div>
				  <div className="tab-pane fade" id="pills-usdt" role="tabpanel" aria-labelledby="pills-usdt-tab" tabIndex="0">
				  	<USDT/>
				  </div>
				</div>
            </div>
		</div>
	);
};

export default Deposit;