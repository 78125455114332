export const SHOW = 'SHOW';
export const HIDE = 'HIDE';

export const show = () => ({
    type: SHOW
});

export const hide = () => ({
    type: HIDE
});
