import { LOGIN, LOGOUT } from './action';

const initialState = {
    login: false
};

const loginReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                login: true
            };
        case LOGOUT:
            return {
                ...state,
                login: false
            };
        default:
            return state;
    }
};

export default loginReducer;
