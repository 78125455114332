import { toast } from 'react-toastify';
import { hide } from '../redux/showhide/action';
import { logout } from '../redux/login/action';

export const handleResponseStatus = (response, result, dispatch, navigate) => {
  switch(response.status) {
    case 401:
      toast.error(result.error || 'Unauthorized', {
        theme: 'colored'
      });
      localStorage.clear();
      dispatch(hide());
      dispatch(logout());
      navigate('/');
      break;

    case 400:
      toast.error(result.message || 'Bad Request', {
        theme: 'colored'
      });
      break;

    case 403:
      toast.error(result.message, {
        theme: 'colored'
      });
      localStorage.clear();
      dispatch(hide());
      dispatch(logout());
      navigate('/');
      break;

    case 404:
      toast.error(result.message || 'Not Found', {
        theme: 'colored'
      });
      break;

    case 500:
      toast.error(result.message , {
        theme: 'colored'
      });
      localStorage.clear();
      dispatch(hide());
      dispatch(logout());
      navigate('/');
      break;

    case 200:
    case 201:
      toast.success(result.message || 'Success', {
        theme: 'colored'
      });
      break;

    default:
      if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
      }
  }
};
