import { useValidationHook } from '../../../hooks/validationHook';
import { usePaymentSlip } from '../../../hooks/paymentslipHook';
const PaymentSlip = () => {
	useValidationHook();
	const { bankInfo, onHandleAmount, onHandleUtr, onHandleFile, handleSubmit, calculateAmount, bankInfoValid, touched } = usePaymentSlip();

	return (
		<div>
			<div className="d-flex justify-content-center">
				<div className="payment-slip">
					<div className="card text-white border border-light my-2">
					  <div className="card-body">
					    <h5 className="card-title text-uppercase fw-bold">Send Your Payment Slip</h5>
					    <form className="row g-3 needs-validation depositForm" onSubmit={handleSubmit} noValidate>
						  	<div className="text-start">
							  <label htmlFor="inputFile" className="form-label">Upload Your Payment Proof Here</label>
							  <input className={`form-control form-control-sm inputFile ${ touched.file && !bankInfoValid.file ? 'is-invalid' : ''}`} type="file" accept="image/*" onInput={onHandleFile} defaultValue={bankInfo.file} required/>
							  <div className="invalid-feedback">
						      	Please Choose a file.
						      </div>
							</div>
							<div className="text-start">
							  <label className="form-label">Unique Transaction Reference</label>
							  <input type="number" className={`form-control ${ touched.utr && !bankInfoValid.utr ? 'is-invalid' : ''}`} placeholder="6 to 12 digits UTR Number" onInput={onHandleUtr} value={bankInfo.utr} required/>
							  <div className="invalid-feedback">
						      	Please Enter Valid UTR Number Between 6 to 12 Digits.
						      </div>
							</div>
							<div className="text-start">
							  <label className="form-label">Amount</label>
							  <input type="number" className={`form-control ${ touched.amount && !bankInfoValid.amount ? 'is-invalid' : ''}`} onInput={onHandleAmount} value={bankInfo.amount} placeholder="Amount" required/>
							  <div className="invalid-feedback">
						      	Please Enter Minimum 100 Amount.
						      </div>
							</div>
							<div className="text-center">
								<div className="d-flex justify-content-center my-2">
									<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(100);
									}}>
									<p>100</p>
									</button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(200);
									}}><p>200</p></button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(500);
									}}><p>500</p></button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(1000);
									}}><p>1K</p></button>
								</div>
								<div className="d-flex justify-content-center my-2">
									<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(2000);
									}}><p>2K</p></button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(3000);
									}}><p>3K</p></button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(5000);
									}}><p>5K</p></button>
								  	<button type="button" className="btn btn-outline-light w-25" style={{marginInline:"2px"}} onClick={(e)=>{
										e.preventDefault();
										calculateAmount(10000);
									}}><p>10K</p></button>
								</div>
							</div>
						  	<div className="col-12">
						      <button className="btn btn-warning fw-bold text-uppercase w-100" type="submit" disabled={!bankInfoValid.utr || !bankInfoValid.amount}>Submit</button>
						 	</div>
						</form>
					  </div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className="payment-slip">
					<div className="card text-white border border-light my-2">
					  <div className="card-body text-start">
					    <h5 className="card-title text-danger fw-bold">Note:</h5>
					    <ol className="ps-3">
					    	<li> AFTER DEPOSIT, UPLOAD SLIP, WRITE YOUR DEPOSIT AMOUNT. </li>
					    	<li> COPY & ENTER THE 12 DIGIT UTR NUMBER. </li>
					    	<li> CLICK SUBMIT BUTTON & RECIEVE POINTS & BONUS INSTANTLY. </li>
					    	<li> NEFT RECIEVING TIME VARIES FROM 40 MINUTES TO 1 HOUR. </li>
					    	<li> SITE IS NOT RESPONSIBLE FOR MONEY DEPOSITED TO OLD, IN-ACTIVE OR CLOSED ACCOUNTS. </li>
					    </ol>
					  </div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className="payment-slip">
					<div className="card border border-light my-2">
					  <div className="card-body text-start">
					  	<div className="table-responsive">
						    <table className="table">
							  <thead>
							    <tr>
							      <th className="bg-transparent border border-0 text-white" scope="col">UTR No</th>
							      <th className="bg-transparent border border-0 text-white" scope="col">Amount</th>
							      <th className="bg-transparent border border-0 text-white" scope="col">Status</th>
							      <th className="bg-transparent border border-0 text-white" scope="col">Date & Time</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr>
							      <td className="bg-transparent border border-0 text-white">677598462964</td>
							      <td className="bg-transparent border border-0 text-white">100</td>
							      <td className="bg-transparent border border-0 fw-bold text-success">Success</td>
							      <td className="bg-transparent border border-0 text-white">23/09 21:21:07</td>
							    </tr>
							    <tr>
							      <td className="bg-transparent border border-0 text-white">628493401717</td>
							      <td className="bg-transparent border border-0 text-white">2000</td>
							      <td className="bg-transparent border border-0 fw-bold text-warning">Pending</td>
							      <td className="bg-transparent border border-0 text-white">23/09 21:41:07</td>
							    </tr>
							    <tr>
							      <td className="bg-transparent border border-0 text-white">692734108212</td>
							      <td className="bg-transparent border border-0 text-white">100</td>
							      <td className="bg-transparent border border-0 fw-bold text-danger">Failed</td>
							      <td className="bg-transparent border border-0 text-white">23/09 23:21:07</td>
							    </tr>
							  </tbody>
							</table>
						</div>
					  </div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentSlip;


